/* You can add global styles to this file, and also import other style files */
:root {
  --positive-sentiment-color-lighter: #4E79A740;
  --positive-sentiment-color: #4E79A7;
  --positive-sentiment-color-darker: #2f4964;
  --negative-sentiment-color-lighter: #F28E2B40;
  --negative-sentiment-color: #F28E2B;
  --negative-sentiment-color-darker: #a1550a;
  --mixed-sentiment-color-lighter: #F1CE6340;
  --mixed-sentiment-color: #F1CE63;
  --mixed-sentiment-color-darker: #bb9111;
  --na-sentiment-color-lighter: #BAB0AC40;
  --na-sentiment-color: #BAB0AC;
  --na-sentiment-color-darker: #756761;
  --unassigned-sentiment-color: #AFB3BD;
  --premera-blue-color: #0099D8;
  --mat-full-pseudo-checkbox-selected-icon-color: #117bab;
  --mat-option-selected-state-label-text-color: #000;
  --mat-select-trigger-text-font: "Roboto", sans-serif;
  --mat-select-trigger-text-size: .9em;
  --mat-full-pseudo-checkbox-unselected-icon-color: #117bab;
  --mat-select-panel-background-color: #fff;
}

.mat-mdc-option .mdc-list-item__primary-text {
    font-size: .9em;
}
